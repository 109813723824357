import React from 'react';

type OwnProps = {
  className?: string;
};

const Born: React.FC<OwnProps> = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 478.916 478.916">
      <g>
        <path
          d="M368.084,245.559h-23.32c-8.976,0-16.289,7.313-16.289,16.292v70.483H223.431h-1.31c-1.833,0-3.574,0.77-4.815,2.111
		c-1.233,1.34-1.849,3.145-1.711,4.962c3.606,44.486,40.847,79.476,86.266,79.476h66.776c47.691,0,86.334-38.582,86.512-86.225
		l0.006-0.517C454.871,284.366,415.919,245.559,368.084,245.559z M347.97,332.141v-67.085h20.114
		c37.092,0,67.253,30.059,67.538,67.085H347.97z"
        />
        <path
          d="M302.545,429.432c-13.092,0-23.749,10.665-23.749,23.757c0,13.1,10.657,23.756,23.749,23.756
		c13.091,0,23.757-10.656,23.757-23.756C326.302,440.097,315.636,429.432,302.545,429.432z M302.545,458.175
		c-2.743,0-4.978-2.234-4.978-4.985c0-2.751,2.234-4.985,4.978-4.985c2.751,0,4.986,2.234,4.986,4.985
		C307.531,455.94,305.296,458.175,302.545,458.175z"
        />
        <path
          d="M368.213,429.432c-13.083,0-23.748,10.665-23.748,23.757c0,13.1,10.665,23.756,23.748,23.756
		c13.1,0,23.757-10.656,23.757-23.756C391.97,440.097,381.313,429.432,368.213,429.432z M368.213,458.175
		c-2.743,0-4.978-2.234-4.978-4.985c0-2.751,2.235-4.985,4.978-4.985c2.752,0,4.987,2.234,4.987,4.985
		C373.2,455.94,370.965,458.175,368.213,458.175z"
        />
        <path
          d="M106.226,139.519c22.987,0,41.614-18.628,41.614-41.613c0-22.986-18.627-41.613-41.614-41.613
		c-22.986,0-41.612,18.627-41.612,41.613C64.614,120.891,83.24,139.519,106.226,139.519z"
        />
        <path
          d="M200.136,50.089c-0.576,0.406-1.124,0.837-1.657,1.279c2.58-0.118,5.222,0.176,7.839,0.943
		c12.721,3.726,20.012,17.058,16.286,29.778l-9.584,32.721l6.562,9.293l-2.758,1.945c-5.677,4.004-8.284,11.099-6.575,17.824
		l10.059,39.334c2.29,8.962,11.406,14.368,20.366,12.08c9.059-2.329,14.333-11.494,12.078-20.368l-7.197-28.163
		c15.874-11.196,15.38-10.498,15.38-10.498l0.006-0.004c12.085-8.524,14.965-25.229,6.436-37.322l-29.919-42.404
		c-8.515-12.073-25.23-14.967-37.314-6.443L200.136,50.089z"
        />
        <path
          d="M196.283,44.611c11.048-7.792,13.73-23.139,5.92-34.21c-7.815-11.079-23.15-13.723-34.222-5.914
		c-11.072,7.81-13.722,23.14-5.907,34.219C169.847,49.727,185.175,52.446,196.283,44.611z"
        />
        <path
          d="M203.233,148.729l-0.215,0.227l-1.557,5.316c-2.255,7.701-8.211,13.765-15.869,16.16c0,0-66.614,12.449-65.559,12.119
		l63.171-19.754c5.106-1.597,9.076-5.64,10.58-10.773l21.143-72.182c2.484-8.48-2.377-17.369-10.857-19.853
		c-8.48-2.486-17.368,2.377-19.853,10.857l-18.735,63.964l-10.892,3.406l-29.769,9.309H74.403c-10.283,0-18.619,8.335-18.619,18.618
		v134.32c-0.096,0.804-0.159,1.617-0.161,2.447l-0.188,68.422L25.156,450.4c-4.148,10.831,1.27,22.974,12.101,27.122
		c2.471,0.946,5.009,1.395,7.506,1.395c8.448,0,16.414-5.136,19.616-13.496l31.657-82.667c0.911-2.379,1.382-4.905,1.389-7.453
		l0.136-49.667h16.16l29.008,43.364l-11.455,86.144c-1.529,11.497,6.552,22.056,18.049,23.585c0.939,0.125,1.872,0.185,2.794,0.185
		c10.367,0,19.387-7.677,20.791-18.234l12.5-94c0.673-5.064-0.522-10.199-3.362-14.444l-25.37-37.925V194.863l36.748,2.383
		c0.347,0.023,0.692,0.034,1.037,0.034c4.381,0,8.589-1.799,11.621-5.003l6.532-6.903c-0.016-0.062-0.039-0.121-0.055-0.184
		L203.233,148.729z"
        />
      </g>
    </svg>
  );
};

export default Born;
