import React from 'react';

type OwnProps = {
  className?: string;
};

const Skype: React.FC<OwnProps> = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 329.978 329.978">
      <g>
        <path
          d="M309.644,192.877c1.732-9.032,2.648-18.352,2.648-27.888c0-81.353-65.954-147.302-147.304-147.302
		c-9.537,0-18.858,0.917-27.891,2.649c-32.52-28.348-81.894-27.063-112.86,3.902c-30.967,30.964-32.253,80.341-3.902,112.863
		c-1.731,9.031-2.648,18.352-2.648,27.888c0,81.354,65.953,147.303,147.302,147.303c9.538,0,18.86-0.917,27.894-2.649
		c32.518,28.348,81.893,27.063,112.859-3.902C336.705,274.776,337.993,225.399,309.644,192.877z M167.795,255.715
		c-43.627,0-79.982-19.5-79.982-43.298c0-10.575,5.946-20.163,19.495-20.163c20.826,0,22.811,29.746,58.511,29.746
		c17.182,0,28.091-7.602,28.091-17.515c0-12.231-10.576-14.213-27.762-18.509l-28.424-6.939
		c-28.099-6.943-49.911-18.51-49.911-50.902c0-39.332,38.998-53.873,72.381-53.873c36.691,0,73.708,14.541,73.708,36.684
		c0,11.236-7.608,21.158-20.163,21.158c-18.841,0-19.507-22.146-49.907-22.146c-16.855,0-27.762,4.626-27.762,14.874
		c0,11.236,10.907,13.877,25.784,17.185l20.156,4.629c27.428,6.275,60.156,17.845,60.156,51.564
		C242.166,237.535,204.15,255.715,167.795,255.715z"
        />
      </g>
    </svg>
  );
};

export default Skype;
